import { useEffect, useState } from "react";

import { updateDataBrevo } from "../../utils/callApi";
import { Link } from "react-router-dom";

export const QuestionAnswer = ({
  questionsAnswerData,
  questionSelected,
  contactRedux,
  setQuestionSelected,
  setShowAndHideQuestion
}) => {
  const [listIds, setListIds] = useState([15]);
  const [answer, setAnswer] = useState("");
  
  const trackingLink = `http://dynuinmedia.go2cloud.org/aff_c?offer_id=9842&aff_id=9057&first=${contactRedux?.firstName}&last=${contactRedux?.lastName}&email=${contactRedux?.email}&city1=${contactRedux?.city}&state=${contactRedux?.state}&zip=${contactRedux?.zipCode}&addr=${contactRedux?.address ?? contactRedux?.streetAddress}&dob=${contactRedux?.dob}`;

  const handleAnswer = (e) => {
    const value = e.target.dataset.value;
    setAnswer(value);
    const questionIndex = questionSelected?.questionIndex;

    if (value === "yes") {
      if (questionIndex === 2) setListIds((prev) => [...prev, 14]);
      else if (questionIndex === 3) setListIds((prev) => [...prev, 13]);
      else setListIds((prev) => [...prev, 12]);

      setQuestionSelected(questionsAnswerData[questionIndex]);
    } else if (value === "no") {
      setQuestionSelected(questionsAnswerData[questionIndex]);
    } else if (value === "Got it") {
      setShowAndHideQuestion(false);
    }
  };

  useEffect(() => {
    if (contactRedux) {
      const { email, firstName, lastName } = contactRedux;

      if (answer === "yes" && questionSelected.questionIndex !== 2)
        updateDataBrevo(
          email,
          firstName,
          lastName,
          listIds,
          contactRedux?.loanReason
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listIds, questionSelected, contactRedux]);

  return (
    <>
      {questionSelected?.icon && (
        <div
          className="icon-qs-aw"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          <img src={questionSelected?.icon} alt="" />
        </div>
      )}

      {questionSelected?.image && (
        <div
          className="icon-qs-aw"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          <Link to={trackingLink} target="blank">
            <img src={questionSelected?.image} alt="" />
          </Link>
        </div>
      )}

      <h3 className="question">{questionSelected?.question ?? ""}</h3>

      <div className="answer">
        {questionSelected?.answer &&
          questionSelected.answer.map((item, key) => {
            if (questionSelected?.image && item.value === "yes") {
              return (
                <Link
                  key={key}
                  to={trackingLink}
                  target="blank"
                  className={
                    item.value === "yes" || item.value === "Got it"
                      ? "active"
                      : ""
                  }
                  onClick={handleAnswer}
                  data-value={item.value}
                >
                  {item?.name}
                </Link>
              );
            }

            return (
              <button
                key={key}
                data-value={item.value}
                className={
                  item.value === "yes" || item.value === "Got it"
                    ? "active"
                    : ""
                }
                onClick={handleAnswer}
              >
                {item?.name}
              </button>
            );
          })}
      </div>
    </>
  );
};
