import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./BestTimeContact.scss";
import Progress from "../../../components/Progress/Progress";
import { options } from "./options";

import step3Icon from "../../../assets/images/step-icon/step4-icon.png";
import checkedIcon from "../../../assets/images/checked.png";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";

const BestTimeContact = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [bestTimeContact, setBestTimeContact] = useState(
    contactRedux?.bestTimeContact ?? options[0].value
  );

  const handleClick = (e, option) => {
    setBestTimeContact(option);
  };

  // custom
  const { handleSubmit } = useForm();

  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(true);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 4,
      lead_id,
      contactTime: bestTimeContact
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/dob");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-4">
      <Navbar />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <Box
          sx={{
            width: "100%",

            margin: "10px auto 30px",
            padding: isMobile && "0 15px"
          }}
        >
          <Progress step={6} />
        </Box>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={step3Icon}
                alt=""
              />
            </Box>
            <h2 className="title">Let us know the best time to contact you</h2>

            <div className="form-control">
              <div className="list-btn-radio">
                {options.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      bestTimeContact === option.value ? "active" : ""
                    }`}
                    onClick={(e) => handleClick(e, option.value)}
                  >
                    {option.label}
                    {bestTimeContact === option.value && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink="/cellphone"
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default BestTimeContact;
