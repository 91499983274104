import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./OwHome.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import checkedIcon from "../../../assets/images/checked.png";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import owHomeIcon from "../../../assets/images/step-icon/owhome-icon.png";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";

import { OwHomeOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";
import { getPrevStepLink } from "../../../utils/functions";

const OwHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [residenceOwnership, setResidenceOwnership] = useState(
    contactRedux?.ownHome ?? OwHomeOptions[0].value
  );

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async () => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    if (!residenceOwnership) {
      setError("residenceOwnership", {
        type: "error",
        message: "Please choose an answer"
      });
      setLoadingButton(false);
      return;
    }

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 8,
      lead_id,
      ownHome: residenceOwnership
    });

    
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/incomesource");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="step-7">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={7} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={owHomeIcon}
                alt=""
              />
            </Box>
            <h2 className="title">
              Do you own or rent your
              <br /> current residence?
            </h2>

            <div className="form-control">
              <div className="list-btn-radio">
                {OwHomeOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      residenceOwnership === option.value ? "active" : ""
                    }`}
                    onClick={() => {
                      setResidenceOwnership(option.value);
                      clearErrors();
                      handleSubmit(onSubmit);
                    }}
                  >
                    {option.label}
                    {residenceOwnership === option.value && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>

              {errors.phone && (
                <span style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "10px",
                  display: "block",
                  textAlign: "center"
                }} className="error">{errors.phone.message}</span>
              )}
            </div>
            {errors?.residenceOwnership && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                    display: "block",
                    textAlign: "center"
                  }}
                  className="error"
                >
                  {errors?.residenceOwnership?.message}
                </span>
              )}

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>

          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default OwHome;
