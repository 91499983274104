import React, { useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./EmpInfor.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import EmpInforIcon from "../../../assets/images/step-icon/EmpInfor-icon.png";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";

import {
  ListBoxCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
// CUSTOM STYLE
import {
  searchEmployerNameApi,
  searchJobTitleApi
} from "../../../utils/callApi";
import { trimWhiteSpaceObjValue, getPrevStepLink } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime } from "../../../utils/const";

const EmpInfor = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const [loadingButton, setLoadingButton] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [jobTitle, setJobTitle] = useState(contactRedux?.jobTitle ?? "");
  const [employerName, setEmployerName] = useState(
    contactRedux?.workCompanyName ?? contactRedux?.employerName ?? ""
  );

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [openJobTitle, setOpenJobTitle] = useState(false);
  const [employerNameOptions, setEmployerNameOptions] = useState([]);
  const [openEmployerName, setOpenEmployerName] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const timeoutRef = useRef(null);

  // CALL API SEARCH JOB TITLE
  const handleSearchJobTitle = async (searchValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = searchValue.length > 0 && searchValue.length < 50;
    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchJobTitleApi(searchValue);
        setJobTitleOptions(res || []);
      }
    }, 300);
  };

  // CALL API SEARCH EMPLOYER NAME
  const handleSearchEmployerName = async (searchValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = searchValue.length > 0 && searchValue.length < 50;
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchEmployerNameApi(searchValue);
        setEmployerNameOptions(res || []);
      }
    }, 300);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispath, contactRedux?._id, {
      step: 15,
      lead_id,
      workCompanyName: employerName,
      jobTitle
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/workphone");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="EmpInfor-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={14} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={EmpInforIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Employer Information</h2>
            <h5>
              This information is used to verify your employment to ensure you
              can repay your loan.
            </h5>
            <div className="form-control">
              {/* employerName */}
              <Controller
                name="employerName"
                control={control}
                defaultValue={employerName}
                rules={{
                  required: "FirstName is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "7px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Your employer name
                    </FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="employerName"
                      name="employerName"
                      // autoComplete="off"
                      onOpen={() => setOpenEmployerName(true)}
                      onClose={() => setOpenEmployerName(false)}
                      disableClearable
                      value={employerName}
                      options={employerNameOptions}
                      onChange={(e, option) => {
                        setEmployerName(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchEmployerName(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          inputRef={ref}
                          {...params}
                          label={employerName ? " " : "e.g. McDonald"}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openEmployerName && employerNameOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          value={employerName}
                          error={!!errors?.employerName}
                          helperText={
                            errors?.employerName &&
                            errors?.employerName?.message
                          }
                          onChange={(e) => setEmployerName(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* employerName */}
              {/* jobTitle */}
              <Controller
                name="jobTitle"
                control={control}
                defaultValue={jobTitle}
                rules={{
                  required: "Job title is required!"
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "20px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>Job title</FormHelperText>
                    <Autocomplete
                      {...field}
                      freeSolo
                      id="jobTitle"
                      name="jobTitle"
                      onOpen={() => setOpenJobTitle(true)}
                      onClose={() => setOpenJobTitle(false)}
                      disableClearable
                      value={jobTitle}
                      options={jobTitleOptions}
                      onChange={(e, option) => {
                        setJobTitle(option);
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleSearchJobTitle(newInputValue);
                        field.onChange(newInputValue);
                      }}
                      ListboxComponent="div"
                      ListboxProps={{ sx: ListBoxCustom }}
                      //PopperComponent={isMobile && "bottom"}
                      noOptionsText={false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label={jobTitle ? "" : "e.g. Sales Associate"}
                          size={isMobile ? "small" : "medium"}
                          sx={textFieldCustom}
                          className={
                            openJobTitle && jobTitleOptions.length > 0
                              ? "show"
                              : ""
                          }
                          InputLabelProps={{
                            shrink: false,
                            style: { ...textInputStyles, color: "#B7B7B7" }
                          }}
                          value={jobTitle}
                          error={!!errors?.jobTitle}
                          helperText={
                            errors?.jobTitle && errors?.jobTitle?.message
                          }
                          onChange={(e) => setJobTitle(e.target.value)}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              {/* jobTitle */}
            </div>

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default EmpInfor;
