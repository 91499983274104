import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./SSN.scss";
import "../../../components/RequestButton/RequestButton.scss"
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import { StepPreFooter } from "../../../components/Footer/StepPreFooter";

import SSNIcon from "../../../assets/images/step-icon/SSN-icon.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PatternFormat } from "react-number-format";

import { useForm, Controller } from "react-hook-form";
import { Box, Button, FormHelperText, TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { labelCustom, textFieldCustom, textInputStyles } from "./style";
// CUSTOM STYLE
import { nextStepDelayTime } from "../../../utils/const";
import { RatesFee } from "../../../components/popups/RatesFee/RatesFee";

const SSN = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingButton, setLoadingButton] = useState(false);
  const [openRateFee, setOpenRateFee] = useState(false);
  
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(
    contactRedux?.socialSecurityNumber ?? ""
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 25,
      lead_id,
      ssn: socialSecurityNumber.split("-").join("")
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/mapping-lender");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="SSN-page">
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={24} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={SSNIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Social Security Number</h2>
            <h5>
              Your SSN is used to verify the individual the loan will be given
              out to.
            </h5>
            <div className="form-control">
              <Controller
                name="socialSecurityNumber"
                control={control}
                defaultValue={socialSecurityNumber}
                rules={{
                  required: "Social Security Number is required!",
                  pattern: {
                    value: /^\d{3}-\d{2}-\d{4}$/,
                    message: "Invalid social security number format xxx-xx-xxxx"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      marginBottom: "15px"
                    }}
                  >
                    <FormHelperText sx={labelCustom}>
                      Social Security Number
                    </FormHelperText>
                    <PatternFormat
                      customInput={TextField}
                      format="###-##-####"
                      //
                      {...field}
                      inputRef={(input) => {
                        input?.addEventListener("focus", () => {
                          input?.setAttribute("inputmode", "numeric");
                        });
                        ref(input);
                      }}
                      id="socialSecurityNumber"
                      // autoComplete="socialSecurityNumber"
                      variant="outlined"
                      fullWidth
                      type="text"
                      error={!!errors?.socialSecurityNumber}
                      placeholder="e.g. 123 - 12 - 1234"
                      helperText={
                        errors?.socialSecurityNumber &&
                        errors?.socialSecurityNumber?.message
                      }
                      InputLabelProps={{
                        shrink: false,
                        style: textInputStyles
                      }}
                      value={socialSecurityNumber}
                      sx={textFieldCustom}
                      size={isMobile ? "small" : "medium"}
                      onChange={(e) => {
                        setSocialSecurityNumber(e.target.value);
                        field.onChange(e.target.value);
                      }}
                    />
                  </FormControl>
                )}
              />
              {/* <Box sx={{ textAlign: "center", margin: "0 0 20px" }}>
                <span
                  style={{
                    color: "#606060",
                    textAlign: "justify",
                    fontFamily: "Open Sans",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal"
                  }}
                >
                  It's required to protect you from fraudulent use of your
                  personal data.
                </span>
              </Box> */}
              {/* routingNumber */}
              <Box sx={{ textAlign: "left", margin: "0 0 20px" }}>
                <span
                  style={{
                    color: "#606060",
                    textAlign: "justify",
                    fontFamily: "Open Sans",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal"
                  }}
                  className="request-term"
                >
                  It's required to protect you from fraudulent use of your
                  personal data.<br />
                  By clicking “Request Funds” you affirm that you have read, 
                  understand, and agree to the 
                  <a href="disclaimer" target="_blank"> Disclaimer</a>, 
                  <a href="privacy" target="_blank"> Privacy Policy</a>, 
                  <a href="/credit-authorization-agreement" target="_blank"> Credit Authorization</a>, 
                  Responsible Lending, <span style={{color: 'blue', cursor: 'pointer'}} onClick={() => setOpenRateFee(true)}>Rates & Fees</span> and <a href="terms-of-use" target="_blank"> Terms of Use</a>. 
                  Your click is your electronic signature, and you authorize us to share 
                  your information with lenders and third party marketers and partners we contact with. 
                  By clicking “Request Funds”, you give your written consent to 
                  1) receive information and marketing e-mail messages and notifications from us 
                  and third party marketers we contract with 
                  2) receive SMS/text messages and autodialed or prerecorded calls from lenders 
                  and third party marketers and partners on the telephone number provided in the form 
                  (even if your number can be found in Do-Not-Call-Lists of any kind). 
                  By clicking "Request Funds," I consent to a soft credit pull, which will not affect your credit score.
                  Please note that if you are pre-qualified, lenders may perform a hard inquiry, which could impact your credit rating. 
                  A list of such partners can be found <a href="/partners" target="_blank">here</a>.
                  In case you do not wish to receive this kind of information by messages, please text “STOP” to 3103400791. 
                  I understand that my consent is not required to obtain a loan.
                </span>
              </Box>
            </div>
            {/* <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink="/creditscore"
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box> */}

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
              className="btn-request"
            >
              <Button
                // onClick={() => navigate("/mapping-lender")}
                onClick={handleSubmit(onSubmit)}
                sx={{
                  borderRadius: "8px",
                  height: "42px",
                  width: "100%",
                  margin: "0 auto",
                  display: "block",
                  backgroundColor: "#FCA905",

                  color: "#000",
                  fontFamily: "Open Sans",
                  fontSize: isMobile ? "14px" : "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",

                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#0060df",
                    opacity: "0.9"
                  }
                }}
              >
                Request Funds
              </Button>
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default SSN;
