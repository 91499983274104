import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./WorkPhone.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import { StepPreFooter } from "../../../components/Footer/StepPreFooter";
import { useLocation } from "react-router-dom";
import { getPrevStepLink } from "../../../utils/functions";

import WorkPhoneIcon from "../../../assets/images/step-icon/WorkPhone-icon.png";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box, FormControl, FormHelperText, TextField } from "@mui/material";

import { formControlCustom, labelCustom, textFieldCustom } from "./style";

import ReactInputMask from "react-input-mask";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";
import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { nextStepDelayTime, phonePattern } from "../../../utils/const";

const WorkPhone = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const [loadingButton, setLoadingButton] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const homePhone = contactRedux?.homePhone ?? "";
  const cellPhone = contactRedux?.cellPhone ?? "";
  const [employerPhone, setEmployerPhone] = useState(
    contactRedux?.workPhone ?? contactRedux?.employerPhone ?? ""
  );

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const checkPhoneEquaWorkphone = (employerPhone, phone) => {
    let stripMask = "";
    if (employerPhone) {
      stripMask = employerPhone.replace(/[()-]/g, "")
    }
    if (stripMask && stripMask === phone) {
      setError("employerPhone", {
        type: "required",
        message:
          "Company phone number must not be the same as personal phone number!"
      });
      return true;
    } else {
      clearErrors("employerPhone");
      return false;
    }
  };

  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    if (checkPhoneEquaWorkphone(employerPhone, cellPhone)) return;
    if (checkPhoneEquaWorkphone(employerPhone, homePhone)) return;
    if (loadingButton) {return}
    setLoadingButton(!loadingButton);

    trimWhiteSpaceObjValue(data);
    if (!errors.employerPhone) {
      const workPhone = employerPhone.replace(/[-()_]/g, "")
      const lead_id = sessionStorage.getItem("lead_id");
      const res = await updateContact(dispath, contactRedux?._id, {lead_id, workPhone, step:16});

      if (res?.status === 200) {
        setTimeout(() => {
          setLoadingButton(false);
          navigate("/deposit");
        }, nextStepDelayTime);
      }
    }
  };

  useEffect(() => {
    checkPhoneEquaWorkphone(employerPhone, homePhone) ||
    checkPhoneEquaWorkphone(employerPhone, cellPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerPhone, setError, clearErrors]);

  return (
    <Box className="WorkPhone-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={15} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={WorkPhoneIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Work Phone Number</h2>
            <h5>
              This phone number is simply used to confirm your employment.
            </h5>
            <div className="form-control">
              {/* employerPhone */}
              <FormHelperText sx={labelCustom}>
                Your work phone number
              </FormHelperText>
              <Controller
                name="employerPhone"
                control={control}
                defaultValue={employerPhone}
                rules={{
                  required: "Phone number is required!",
                  pattern: {
                    value: phonePattern,
                    message: "The phone number is not correct!"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormControl
                    fullWidth
                    sx={{
                      ...formControlCustom,
                      marginBottom: "20px"
                    }}
                  >
                    <ReactInputMask
                      {...field}
                      mask="(999)-999-9999"
                      maskChar="_"
                    >
                      {() => (
                        <TextField
                          {...field}
                          inputRef={(input) => {
                            input?.addEventListener("focus", () => {
                              input?.setAttribute("inputmode", "numeric");
                            });
                            ref(input);
                          }}
                          fullWidth
                          sx={textFieldCustom}
                          size={isMobile ? "small" : "medium"}
                          error={!!errors?.employerPhone}
                          helperText={
                            errors?.employerPhone &&
                            errors?.employerPhone?.message
                          }
                          placeholder="e.g. (123)-123-1234 "
                          InputLabelProps={{ shrink: false }}
                          onInput={(e) => setEmployerPhone(e.target.value)}
                        />
                      )}
                    </ReactInputMask>
                  </FormControl>
                )}
              />
              {/* employerPhone */}
            </div>
            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>

      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default WorkPhone;
