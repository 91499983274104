import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import "./CreditScore.scss";
import Progress from "../../../components/Progress/Progress";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";

import { StepPreFooter } from "../../../components/Footer/StepPreFooter";
import { useLocation } from "react-router-dom";
import { getPrevStepLink } from "../../../utils/functions";

import creditIcon from "../../../assets/images/step-icon/credit-icon.png";
import checkedIcon from "../../../assets/images/checked.png";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { Box } from "@mui/material";

import { StepBtn } from "../../../components/Button/StepBtn/StepBtn";
import { creditScoreRatingOptions } from "../../../utils/options";
import { nextStepDelayTime } from "../../../utils/const";

const CreditScore = () => {
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [creditScoreRating, setCreditScoreRating] = useState(
    contactRedux?.creditScore ?? ""
  );
  const [loadingButton, setLoadingButton] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLoc = useLocation();
  const prevLink = getPrevStepLink(currentLoc.pathname);

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSubmit = async () => {
    if (!creditScoreRating) {
      setError("creditScoreRating", {
        type: "invalid",
        message: "Please select one option."
      });
      return;
    }
    setLoadingButton(true);

    if (!creditScoreRating) {
      setError("creditScoreRating", {
        type: "invalid",
        message: "Please select one option."
      });
      return;
    }

    const lead_id = sessionStorage.getItem("lead_id");
    const res = await updateContact(dispatch, contactRedux?._id, {
      step: 24,
      lead_id,
      creditScore: creditScoreRating
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/ssn");
      }, nextStepDelayTime);
    }
  };

  return (
    <Box className="CreditScore-page">
      <Navbar />
      <ScrollToTop />
      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          margin: "20px auto"
        }}
      >
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",

                margin: "10px auto 30px",
                padding: isMobile && "0 15px"
              }}
            >
              <Progress step={23} />
            </Box>
            <Box sx={{ textAlign: "center", marginBottom: "11px" }}>
              <img
                style={{
                  maxWidth: isMobile && "42px"
                }}
                src={creditIcon}
                alt=""
              />
            </Box>
            <h2 className="title">Credit Score</h2>
            <h5>
              To your best knowledge, tell us your credit score. Don’t worry, we
              consider all credit types.
            </h5>

            <div className="form-control">
              <div className="list-btn-radio">
                {creditScoreRatingOptions.map((option, index) => (
                  <button
                    key={index}
                    className={`btn-radio ${
                      creditScoreRating === option.value ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setCreditScoreRating(option.value);
                      clearErrors("creditScoreRating");
                    }}
                    defaultValue={creditScoreRating}
                  >
                    {option.label}
                    {creditScoreRating === option.value && (
                      <img className="checked-icon" src={checkedIcon} alt="" />
                    )}
                  </button>
                ))}
              </div>
            </div>

            {errors?.creditScoreRating && (
              <span
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginBottom: "10px",
                  display: "block",
                  textAlign: "center"
                }}
                className="error"
              >
                {errors.creditScoreRating.message}
              </span>
            )}

            <Box
              sx={{
                maxWidth: "474px",
                margin: "0 auto"
              }}
            >
              <StepBtn
                prevLink={prevLink}
                onSubmit={handleSubmit(onSubmit)}
                loading={loadingButton}
              />
            </Box>
          </form>
          <StepPreFooter />
        </div>
      </Box>
      {/* <Footer desc={false} /> */}
    </Box>
  );
};

export default CreditScore;
